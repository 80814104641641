.new-user-pop-mask{
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, .5);
}
.new-user-pop{
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    z-index: 9999999;
}
.new-user-concent{
    height: 92.4vw;
    position: absolute;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #FFFFFF;
    border-radius: 15px 15px 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    left: 0;
    width: 100%;
    padding: 0 4.8vw;
    padding-bottom: 10.4vw;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.new-user-close{
    position: absolute;
    right: 4.8vw;
    width: 5.8vw;
    top: 8.5vw;
}
.new-user-imgage{
    display: block;
    margin-top: 6.8vw;
    width: 23.2vw;
}
.new-user-title{
    margin-top: 6.4vw;
    font-size: 5.3vw;
    color: #333;
    font-family: PingFangSC-Medium, PingFang SC, Noto Sans SC;
}
.new-user-connnent{
    margin-top: 3.2vw;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #333;
    font-size: 4vw;
}
.new-user-ok{
    margin-top: 8.5vw;
    height: 11.7vw;
    background: #FFC94F;
    border-radius: 22.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 4.5vw;
    color: #333;
    width: 100%;
    font-family: PingFangSC-Regular, PingFang SC;
}
.newUserMsk_isFocus{
    width: 100vw;
    height: 103.06vw;
    background-size: 100% 100%;
    position: relative;
    padding-top: 7.2vw;
    background-repeat: no-repeat;
}
.newUserMsk_isFocus_header{
    font-size: 6.4vw;
    font-family: PingFangSC-Semibold, PingFang SC, Noto Sans SC;
    color: #222222;
    text-align: center;
}
.newUserMsk_isFocus_section{
    margin-top: 6.4vw;
    width: 80.8vw;
    height: 76.53vw;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(255, 150, 38, .08);
            box-shadow: 0px 2px 10px 0px rgba(255, 150, 38, .08);
    border-radius: 3.2vw;
    margin-left: 9.6vw;
}
.newUserMsk_isFocus_section .newUserMsk_isFocus_section_text:nth-child(1){
    padding-top: 5.86vw;
}
.newUserMsk_isFocus_section_text{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 4.26vw;
    font-family: PingFangSC-Semibold, PingFang SC, Noto Sans SC;
    color: #333333;
    margin-bottom: 4.26vw;
}
.newUserMsk_isFocus_section_text img{
    width: 5.86vw;
    height: 5.86vw;
}
.newUserMsk_isFocus_section_cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 2vw;
}
.newUserMsk_isFocus_section_cont>div{
    width: 32vw;
    height: 32vw;
    background: rgba(255, 201, 79, .2);
    border-radius: 1.6vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.newUserMsk_isFocus_section_cont>div>div{
    padding: 1vw;
    background: #FFFFFF;
    border-radius: 1vw;
}
.newUserMsk_isFocus_section_cont img{
    width: 25.06vw;
    height: 25.06vw;
}
.newUserMsk_isFocus_section_contText{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.newUserMsk_isFocus_section_contText>div{
    width: 41vw;
    font-size: 4vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6B748B;
    text-align: center;
    line-height: 5.6vw
}
.partyMskShow{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 99999999;
}
.partyMskShow_box{
    position: relative;
    width: 80.53vw;
    margin: calc(50vh - 64vw) auto 0;
}
.partyMskShow_img{
    width: 80.53vw;
    height: 100vw;
    background-size:100% 100%;
}
.partyMskShow_close{
    width: 15vw;
    height: 13vw;
    margin: 6.4vw auto 0;
}
.partyMskShow_close img{
    width: 8.53vw;
    height: 8.53vw;
}
.newUserMskShow{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 9999999;
}
.newUserMskShow_box{
    position: absolute;
    top: calc(50vh - 61.2vw);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80.93vw;
}
.newUserMskShow_img{
    width: 80.93vw;
    height: 17.4vw;
    background-size: 100% 100%;
    padding-top: 85vw;
}
.newUserMskShow_img>div{
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 63.73vw;
    height: 10.67vw;
    background: #FFC94F;
    border-radius: 5.33vw;
    font-size: 4.26vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}
.newUserMskShow_close{
    position: absolute;
    bottom: -13.86vw;
    left: 0;
    right: 0;
    width: 7.46vw;
    margin: 0 auto;
}
.newUserMskShow_close img{
    width: 7.46vw;
    height: 7.46vw;
}