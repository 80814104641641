*, ::before, ::after {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x:  ;
        --tw-pan-y:  ;
        --tw-pinch-zoom:  ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position:  ;
        --tw-gradient-via-position:  ;
        --tw-gradient-to-position:  ;
        --tw-ordinal:  ;
        --tw-slashed-zero:  ;
        --tw-numeric-figure:  ;
        --tw-numeric-spacing:  ;
        --tw-numeric-fraction:  ;
        --tw-ring-inset:  ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(59 130 246 / 0.5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur:  ;
        --tw-brightness:  ;
        --tw-contrast:  ;
        --tw-grayscale:  ;
        --tw-hue-rotate:  ;
        --tw-invert:  ;
        --tw-saturate:  ;
        --tw-sepia:  ;
        --tw-drop-shadow:  ;
        --tw-backdrop-blur:  ;
        --tw-backdrop-brightness:  ;
        --tw-backdrop-contrast:  ;
        --tw-backdrop-grayscale:  ;
        --tw-backdrop-hue-rotate:  ;
        --tw-backdrop-invert:  ;
        --tw-backdrop-opacity:  ;
        --tw-backdrop-saturate:  ;
        --tw-backdrop-sepia:  ;
        --tw-contain-size:  ;
        --tw-contain-layout:  ;
        --tw-contain-paint:  ;
        --tw-contain-style:  ;
}
::backdrop {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x:  ;
        --tw-pan-y:  ;
        --tw-pinch-zoom:  ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position:  ;
        --tw-gradient-via-position:  ;
        --tw-gradient-to-position:  ;
        --tw-ordinal:  ;
        --tw-slashed-zero:  ;
        --tw-numeric-figure:  ;
        --tw-numeric-spacing:  ;
        --tw-numeric-fraction:  ;
        --tw-ring-inset:  ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(59 130 246 / 0.5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur:  ;
        --tw-brightness:  ;
        --tw-contrast:  ;
        --tw-grayscale:  ;
        --tw-hue-rotate:  ;
        --tw-invert:  ;
        --tw-saturate:  ;
        --tw-sepia:  ;
        --tw-drop-shadow:  ;
        --tw-backdrop-blur:  ;
        --tw-backdrop-brightness:  ;
        --tw-backdrop-contrast:  ;
        --tw-backdrop-grayscale:  ;
        --tw-backdrop-hue-rotate:  ;
        --tw-backdrop-invert:  ;
        --tw-backdrop-opacity:  ;
        --tw-backdrop-saturate:  ;
        --tw-backdrop-sepia:  ;
        --tw-contain-size:  ;
        --tw-contain-layout:  ;
        --tw-contain-paint:  ;
        --tw-contain-style:  ;
}
    :root {
        --bg-page: #FFFFFF;
        --tab-white: #FFFFFF;
        --tab-selected-white: #FFFFFF;
        --modal-white: #FFFFFF;
        --card-bg-white: rgba(255, 255, 255, .89804);
        --black: #333333;
        --modal-text-black: #333333;
        --icon-black: #333333;
        --gray-1: #6B748B;
        --button-sort-gray: #6B748B;
        --gray-2: #ADB4C6;
        --text-disabled-gray: rgba(173, 180, 198, .6);
        --tab-shadow-gray: rgba(173, 180, 198, .10196);
        --divider-gray: #E7EDFF;
        --tab-divider-gray: #E7EDFF;
        --boder-button-gray: #E7EDFF;
        --modal-indicator-gray: rgba(173, 180, 198, .2);
        --modal-tab-selected-orange: rgba(255, 127, 91, .12157);
        --modal-bg-blue: rgba(231, 237, 255, .4);
        --bg-primary-blue: rgba(231, 237, 255, .4);
        --modal-bg-input-blue: rgba(231, 237, 255, .4);
        --background-datapickter-hot: #F5F8FF;
        --brown: #593712;
        --card-bg-white-card: rgba(255, 255, 255, .3);
        --vip-text: #FCCEA2;
        --icon-hot-blue-fill:rgba(109, 143, 253, .12);
        --border-icon-hot-blue:rgba(109, 143, 253, .30196);
        --lake-green:#46DCE4;
        --orange-fill:rgba(255, 127, 91, .4);
        --modal-text-white:#333;
    }

    :root[class~="dark"] {
        --bg-page: #101116;
        --tab-white: #191B27;
        --tab-selected-white: #101116;
        --modal-white: #191B27;
        --card-bg-white: rgba(25, 27, 39, .89804);
        --black: #F3F4F8;
        --modal-text-black: #F3F4F8;
        --icon-black: #F3F4F8;
        --gray-1: #ADB4C6;
        --button-sort-gray: #FF7F5B;
        --gray-2: #6B748B;
        --text-disabled-gray: rgba(107, 116, 139, .6);
        --tab-shadow-gray: rgba(0, 0, 0, .10196);
        --divider-gray: #1D2331;
        --tab-divider-gray: #101116;
        --boder-button-gray: rgba(231, 237, 255, .10196);
        --modal-indicator-gray: #101116;
        --modal-tab-selected-orange: #101116;
        --modal-bg-blue: #1D2331;
        --bg-primary-blue: #191B27;
        --modal-bg-input-blue: rgba(231, 237, 255, .05098);
        --background-datapickter-hot: #1D2331;
        --brown: #DBB89A;
        --card-bg-white-card: rgba(16, 17, 22, .20);
        --vip-text: #1E140D;
        --icon-hot-blue-fill:rgba(109, 143, 253, .20);
        --modal-text-white:rgba(255, 255, 255, .85);
    }
.\!container {
        width: 100% !important;
}
.container {
        width: 100%;
}
@media (min-width: 640px) {
        .\!container {
                max-width: 640px !important;
        }
        .container {
                max-width: 640px;
        }
}
@media (min-width: 768px) {
        .\!container {
                max-width: 768px !important;
        }
        .container {
                max-width: 768px;
        }
}
@media (min-width: 1024px) {
        .\!container {
                max-width: 1024px !important;
        }
        .container {
                max-width: 1024px;
        }
}
@media (min-width: 1280px) {
        .\!container {
                max-width: 1280px !important;
        }
        .container {
                max-width: 1280px;
        }
}
@media (min-width: 1536px) {
        .\!container {
                max-width: 1536px !important;
        }
        .container {
                max-width: 1536px;
        }
}
.pointer-events-none {
        pointer-events: none;
}
.\!visible {
        visibility: visible !important;
}
.visible {
        visibility: visible;
}
.static {
        position: static;
}
.fixed {
        position: fixed;
}
.absolute {
        position: absolute;
}
.relative {
        position: relative;
}
.sticky {
        position: sticky;
}
.inset-0 {
        inset: 0px;
}
.-bottom-\[0\.8vw\] {
        bottom: -0.8vw;
}
.-left-50 {
        left: -6.667vw;
}
.-left-9 {
        left: -1.200vw;
}
.-right-\[100vw\] {
        right: -100vw;
}
.-top-1 {
        top: -0.133vw;
}
.-top-100 {
        top: -13.333vw;
}
.-top-15 {
        top: -2.000vw;
}
.-top-28 {
        top: -3.733vw;
}
.-top-95 {
        top: -12.667vw;
}
.-top-96 {
        top: -12.800vw;
}
.bottom-0 {
        bottom: 0px;
}
.bottom-10 {
        bottom: 1.333vw;
}
.bottom-160 {
        bottom: 21.333vw;
}
.bottom-170 {
        bottom: 22.667vw;
}
.bottom-2 {
        bottom: 0.267vw;
}
.bottom-22 {
        bottom: 2.933vw;
}
.bottom-235 {
        bottom: 31.333vw;
}
.bottom-32 {
        bottom: 4.267vw;
}
.bottom-48 {
        bottom: 6.400vw;
}
.bottom-54 {
        bottom: 7.200vw;
}
.bottom-76 {
        bottom: 10.133vw;
}
.bottom-\[0\.5555vw\] {
        bottom: 0.5555vw;
}
.bottom-\[17vw\] {
        bottom: 17vw;
}
.left-0 {
        left: 0px;
}
.left-115 {
        left: 15.333vw;
}
.left-12 {
        left: 1.600vw;
}
.left-2 {
        left: 0.267vw;
}
.left-20 {
        left: 2.667vw;
}
.left-230 {
        left: 30.667vw;
}
.left-24 {
        left: 3.200vw;
}
.left-30 {
        left: 4.000vw;
}
.left-355 {
        left: 47.333vw;
}
.left-36 {
        left: 4.800vw;
}
.left-40 {
        left: 5.333vw;
}
.left-42 {
        left: 5.600vw;
}
.left-52 {
        left: 6.933vw;
}
.left-580 {
        left: 77.333vw;
}
.left-6 {
        left: 0.800vw;
}
.left-7 {
        left: 0.933vw;
}
.left-70 {
        left: 9.333vw;
}
.left-90 {
        left: 12.000vw;
}
.left-\[-2vw\] {
        left: -2vw;
}
.left-\[28\.6vw\] {
        left: 28.6vw;
}
.right-0 {
        right: 0px;
}
.right-10 {
        right: 1.333vw;
}
.right-136 {
        right: 18.133vw;
}
.right-162 {
        right: 21.600vw;
}
.right-2 {
        right: 0.267vw;
}
.right-20 {
        right: 2.667vw;
}
.right-24 {
        right: 3.200vw;
}
.right-56 {
        right: 7.467vw;
}
.right-60 {
        right: 8.000vw;
}
.right-\[-1vw\] {
        right: -1vw;
}
.right-\[-2vw\] {
        right: -2vw;
}
.top-0 {
        top: 0px;
}
.top-10 {
        top: 1.333vw;
}
.top-156 {
        top: 20.800vw;
}
.top-16 {
        top: 2.133vw;
}
.top-160 {
        top: 21.333vw;
}
.top-163 {
        top: 21.733vw;
}
.top-24 {
        top: 3.200vw;
}
.top-258 {
        top: 34.400vw;
}
.top-26 {
        top: 3.467vw;
}
.top-28 {
        top: 3.733vw;
}
.top-292 {
        top: 38.933vw;
}
.top-30 {
        top: 4.000vw;
}
.top-342 {
        top: 45.600vw;
}
.top-5 {
        top: 0.667vw;
}
.top-6 {
        top: 0.800vw;
}
.top-60 {
        top: 8.000vw;
}
.top-64 {
        top: 8.533vw;
}
.top-8 {
        top: 1.067vw;
}
.top-88 {
        top: 11.733vw;
}
.top-\[-2\.205vw\] {
        top: -2.205vw;
}
.top-\[100\.876vw\] {
        top: 100.876vw;
}
.top-\[11\.7vw\] {
        top: 11.7vw;
}
.top-\[2vw\] {
        top: 2vw;
}
.top-\[74\.284vw\] {
        top: 74.284vw;
}
.z-0 {
        z-index: 0;
}
.z-10 {
        z-index: 10;
}
.z-20 {
        z-index: 20;
}
.z-30 {
        z-index: 30;
}
.z-50 {
        z-index: 50;
}
.z-7777 {
        z-index: 7777;
}
.z-8888 {
        z-index: 8888;
}
.z-9999 {
        z-index: 999999;
}
.z-\[0\] {
        z-index: 0;
}
.col-span-2 {
        grid-column: span 2 / span 2;
}
.col-span-3 {
        grid-column: span 3 / span 3;
}
.col-span-4 {
        grid-column: span 4 / span 4;
}
.float-left {
        float: left;
}
.m-\[-0\.8vw\] {
        margin: -0.8vw;
}
.m-auto {
        margin: auto;
}
.mx-36 {
        margin-left: 4.800vw;
        margin-right: 4.800vw;
}
.mx-\[1vw\] {
        margin-left: 1vw;
        margin-right: 1vw;
}
.mx-auto {
        margin-left: auto;
        margin-right: auto;
}
.my-0 {
        margin-top: 0px;
        margin-bottom: 0px;
}
.my-15 {
        margin-top: 2.000vw;
        margin-bottom: 2.000vw;
}
.my-\[1vw\] {
        margin-top: 1vw;
        margin-bottom: 1vw;
}
.-ml-100 {
        margin-left: -13.333vw;
}
.-ml-36 {
        margin-left: -4.800vw;
}
.-mr-190 {
        margin-right: -25.333vw;
}
.-mt-2 {
        margin-top: -0.267vw;
}
.-mt-\[4\.35vw\] {
        margin-top: -4.35vw;
}
.mb-10 {
        margin-bottom: 1.333vw;
}
.mb-140 {
        margin-bottom: 18.667vw;
}
.mb-15 {
        margin-bottom: 2.000vw;
}
.mb-204 {
        margin-bottom: 27.200vw;
}
.mb-24 {
        margin-bottom: 3.200vw;
}
.mb-25 {
        margin-bottom: 3.333vw;
}
.mb-26 {
        margin-bottom: 3.467vw;
}
.mb-32 {
        margin-bottom: 4.267vw;
}
.mb-36 {
        margin-bottom: 4.800vw;
}
.mb-360 {
        margin-bottom: 48.000vw;
}
.mb-37 {
        margin-bottom: 4.933vw;
}
.mb-40 {
        margin-bottom: 5.333vw;
}
.mb-42 {
        margin-bottom: 5.600vw;
}
.mb-46 {
        margin-bottom: 6.133vw;
}
.mb-48 {
        margin-bottom: 6.400vw;
}
.mb-64 {
        margin-bottom: 8.533vw;
}
.mb-80 {
        margin-bottom: 10.667vw;
}
.mb-\[2\.4vw\] {
        margin-bottom: 2.4vw;
}
.mb-\[6\.4vw\] {
        margin-bottom: 6.4vw;
}
.ml-12 {
        margin-left: 1.600vw;
}
.ml-16 {
        margin-left: 2.133vw;
}
.ml-18 {
        margin-left: 2.400vw;
}
.ml-24 {
        margin-left: 3.200vw;
}
.ml-3 {
        margin-left: 0.400vw;
}
.ml-32 {
        margin-left: 4.267vw;
}
.ml-36 {
        margin-left: 4.800vw;
}
.ml-4 {
        margin-left: 0.533vw;
}
.ml-7 {
        margin-left: 0.933vw;
}
.ml-8 {
        margin-left: 1.067vw;
}
.ml-\[1\.067vw\] {
        margin-left: 1.067vw;
}
.ml-\[1\.6vw\] {
        margin-left: 1.6vw;
}
.ml-\[14\.261vw\] {
        margin-left: 14.261vw;
}
.ml-\[1vw\] {
        margin-left: 1vw;
}
.ml-\[4\.267vw\] {
        margin-left: 4.267vw;
}
.ml-\[4\.8vw\] {
        margin-left: 4.8vw;
}
.mr-16 {
        margin-right: 2.133vw;
}
.mr-32 {
        margin-right: 4.267vw;
}
.mr-36 {
        margin-right: 4.800vw;
}
.mr-48 {
        margin-right: 6.400vw;
}
.mr-5 {
        margin-right: 0.667vw;
}
.mr-8 {
        margin-right: 1.067vw;
}
.mr-\[0\.2vw\] {
        margin-right: 0.2vw;
}
.mr-\[1\.5vw\] {
        margin-right: 1.5vw;
}
.mr-\[1vw\] {
        margin-right: 1vw;
}
.mr-\[2vw\] {
        margin-right: 2vw;
}
.mr-\[4\.8vw\] {
        margin-right: 4.8vw;
}
.mr-\[5\.333vw\] {
        margin-right: 5.333vw;
}
.mt-1 {
        margin-top: 0.133vw;
}
.mt-10 {
        margin-top: 1.333vw;
}
.mt-12 {
        margin-top: 1.600vw;
}
.mt-150 {
        margin-top: 20.000vw;
}
.mt-16 {
        margin-top: 2.133vw;
}
.mt-165 {
        margin-top: 22.000vw;
}
.mt-17 {
        margin-top: 2.267vw;
}
.mt-18 {
        margin-top: 2.400vw;
}
.mt-2 {
        margin-top: 0.267vw;
}
.mt-20 {
        margin-top: 2.667vw;
}
.mt-21 {
        margin-top: 2.800vw;
}
.mt-231 {
        margin-top: 30.800vw;
}
.mt-24 {
        margin-top: 3.200vw;
}
.mt-25 {
        margin-top: 3.333vw;
}
.mt-27 {
        margin-top: 3.600vw;
}
.mt-28 {
        margin-top: 3.733vw;
}
.mt-30 {
        margin-top: 4.000vw;
}
.mt-319 {
        margin-top: 42.533vw;
}
.mt-32 {
        margin-top: 4.267vw;
}
.mt-36 {
        margin-top: 4.800vw;
}
.mt-4 {
        margin-top: 0.533vw;
}
.mt-40 {
        margin-top: 5.333vw;
}
.mt-48 {
        margin-top: 6.400vw;
}
.mt-5 {
        margin-top: 0.667vw;
}
.mt-52 {
        margin-top: 6.933vw;
}
.mt-6 {
        margin-top: 0.800vw;
}
.mt-64 {
        margin-top: 8.533vw;
}
.mt-7 {
        margin-top: 0.933vw;
}
.mt-75 {
        margin-top: 10.000vw;
}
.mt-76 {
        margin-top: 10.133vw;
}
.mt-8 {
        margin-top: 1.067vw;
}
.mt-80 {
        margin-top: 10.667vw;
}
.mt-88 {
        margin-top: 11.733vw;
}
.mt-96 {
        margin-top: 12.800vw;
}
.mt-\[-0\.266vw\] {
        margin-top: -0.266vw;
}
.mt-\[-10vw\] {
        margin-top: -10vw;
}
.mt-\[-12vw\] {
        margin-top: -12vw;
}
.mt-\[-4vw\] {
        margin-top: -4vw;
}
.mt-\[-5vw\] {
        margin-top: -5vw;
}
.mt-\[0\.5vw\] {
        margin-top: 0.5vw;
}
.mt-\[18vw\] {
        margin-top: 18vw;
}
.mt-\[2\.1vw\] {
        margin-top: 2.1vw;
}
.mt-\[2\.267vw\] {
        margin-top: 2.267vw;
}
.mt-\[20px\] {
        margin-top: 20px;
}
.mt-\[2vw\] {
        margin-top: 2vw;
}
.mt-\[56\.27vw\] {
        margin-top: 56.27vw;
}
.mt-\[6\.4vw\] {
        margin-top: 6.4vw;
}
.box-border {
        box-sizing: border-box;
}
.line-clamp-1 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
}
.line-clamp-2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
}
.line-clamp-3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
}
.line-clamp-4 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
}
.line-clamp-5 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
}
.block {
        display: block;
}
.inline-block {
        display: inline-block;
}
.inline {
        display: inline;
}
.flex {
        display: flex;
}
.inline-flex {
        display: inline-flex;
}
.table {
        display: table;
}
.grid {
        display: grid;
}
.contents {
        display: contents;
}
.hidden {
        display: none;
}
.size-18 {
        width: 2.400vw;
        height: 2.400vw;
}
.size-24 {
        width: 3.200vw;
        height: 3.200vw;
}
.size-26 {
        width: 3.467vw;
        height: 3.467vw;
}
.size-280 {
        width: 37.333vw;
        height: 37.333vw;
}
.size-30 {
        width: 4.000vw;
        height: 4.000vw;
}
.size-32 {
        width: 4.267vw;
        height: 4.267vw;
}
.size-34 {
        width: 4.533vw;
        height: 4.533vw;
}
.size-38 {
        width: 5.067vw;
        height: 5.067vw;
}
.size-40 {
        width: 5.333vw;
        height: 5.333vw;
}
.size-48 {
        width: 6.400vw;
        height: 6.400vw;
}
.size-54 {
        width: 7.200vw;
        height: 7.200vw;
}
.size-80 {
        width: 10.667vw;
        height: 10.667vw;
}
.h-0 {
        height: 0px;
}
.h-1 {
        height: 0.133vw;
}
.h-102 {
        height: 13.600vw;
}
.h-110 {
        height: 14.667vw;
}
.h-111 {
        height: 14.800vw;
}
.h-116 {
        height: 15.467vw;
}
.h-117 {
        height: 15.600vw;
}
.h-12 {
        height: 1.600vw;
}
.h-128 {
        height: 17.067vw;
}
.h-13 {
        height: 1.733vw;
}
.h-132 {
        height: 17.600vw;
}
.h-14 {
        height: 1.867vw;
}
.h-15 {
        height: 2.000vw;
}
.h-16 {
        height: 2.133vw;
}
.h-163 {
        height: 21.733vw;
}
.h-166 {
        height: 22.133vw;
}
.h-18 {
        height: 2.400vw;
}
.h-183 {
        height: 24.400vw;
}
.h-188 {
        height: 25.067vw;
}
.h-200 {
        height: 26.667vw;
}
.h-22 {
        height: 2.933vw;
}
.h-235 {
        height: 31.333vw;
}
.h-236 {
        height: 31.467vw;
}
.h-24 {
        height: 3.200vw;
}
.h-250 {
        height: 33.333vw;
}
.h-252 {
        height: 33.600vw;
}
.h-272 {
        height: 36.267vw;
}
.h-274 {
        height: 36.533vw;
}
.h-28 {
        height: 3.733vw;
}
.h-30 {
        height: 4.000vw;
}
.h-310 {
        height: 41.333vw;
}
.h-32 {
        height: 4.267vw;
}
.h-34 {
        height: 4.533vw;
}
.h-340 {
        height: 45.333vw;
}
.h-352 {
        height: 46.933vw;
}
.h-38 {
        height: 5.067vw;
}
.h-40 {
        height: 5.333vw;
}
.h-412 {
        height: 54.933vw;
}
.h-42 {
        height: 5.600vw;
}
.h-46 {
        height: 6.133vw;
}
.h-465 {
        height: 62.000vw;
}
.h-48 {
        height: 6.400vw;
}
.h-50 {
        height: 6.667vw;
}
.h-52 {
        height: 6.933vw;
}
.h-520 {
        height: 69.333vw;
}
.h-523 {
        height: 69.733vw;
}
.h-54 {
        height: 7.200vw;
}
.h-55 {
        height: 7.333vw;
}
.h-56 {
        height: 7.467vw;
}
.h-6 {
        height: 0.800vw;
}
.h-62 {
        height: 8.267vw;
}
.h-64 {
        height: 8.533vw;
}
.h-68 {
        height: 9.067vw;
}
.h-72 {
        height: 9.600vw;
}
.h-74 {
        height: 9.867vw;
}
.h-75 {
        height: 10.000vw;
}
.h-76 {
        height: 10.133vw;
}
.h-78 {
        height: 10.400vw;
}
.h-80 {
        height: 10.667vw;
}
.h-85 {
        height: 11.333vw;
}
.h-86 {
        height: 11.467vw;
}
.h-88 {
        height: 11.733vw;
}
.h-90 {
        height: 12.000vw;
}
.h-96 {
        height: 12.800vw;
}
.h-\[0\.133vw\] {
        height: 0.133vw;
}
.h-\[1\.33vw\] {
        height: 1.33vw;
}
.h-\[1\.6vw\] {
        height: 1.6vw;
}
.h-\[10\.739vw\] {
        height: 10.739vw;
}
.h-\[100vh\] {
        height: 100vh;
}
.h-\[100vw\] {
        height: 100vw;
}
.h-\[11\.2vw\] {
        height: 11.2vw;
}
.h-\[11\.467vw\] {
        height: 11.467vw;
}
.h-\[12\.8vw\] {
        height: 12.8vw;
}
.h-\[12vw\] {
        height: 12vw;
}
.h-\[13\.067vw\] {
        height: 13.067vw;
}
.h-\[13\.3vw\] {
        height: 13.3vw;
}
.h-\[13\.6vw\] {
        height: 13.6vw;
}
.h-\[13vw\] {
        height: 13vw;
}
.h-\[15\.88vw\] {
        height: 15.88vw;
}
.h-\[17\.6vw\] {
        height: 17.6vw;
}
.h-\[19\.2vw\] {
        height: 19.2vw;
}
.h-\[191\.467vw\] {
        height: 191.467vw;
}
.h-\[2\.4vw\] {
        height: 2.4vw;
}
.h-\[28\.533vw\] {
        height: 28.533vw;
}
.h-\[4\.8vw\] {
        height: 4.8vw;
}
.h-\[4\.93vw\] {
        height: 4.93vw;
}
.h-\[5\.067vw\] {
        height: 5.067vw;
}
.h-\[5\.33vw\] {
        height: 5.33vw;
}
.h-\[56\.27vw\] {
        height: 56.27vw;
}
.h-\[5vw\] {
        height: 5vw;
}
.h-\[72\.5vw\] {
        height: 72.5vw;
}
.h-\[86vw\] {
        height: 86vw;
}
.h-\[8vw\] {
        height: 8vw;
}
.h-\[9\.73vw\] {
        height: 9.73vw;
}
.h-\[calc\(100vh-11\.7vw\)\] {
        height: calc(100vh - 11.7vw);
}
.h-\[calc\(100vh-14\.5vw-27\.5vw\)\] {
        height: calc(100vh - 14.5vw - 27.5vw);
}
.h-\[calc\(100vh-17\.5vw\)\] {
        height: calc(100vh - 17.5vw);
}
.h-\[calc\(100vh-27\.5vw\)\] {
        height: calc(100vh - 27.5vw);
}
.h-\[calc\(100vh-39vw\)\] {
        height: calc(100vh - 39vw);
}
.h-\[calc\(100vh-57vw\)\] {
        height: calc(100vh - 57vw);
}
.h-\[calc\(100vh-61\.38vw\)\] {
        height: calc(100vh - 61.38vw);
}
.h-\[calc\(100vh-71\.38vw\)\] {
        height: calc(100vh - 71.38vw);
}
.h-\[calc\(82\.5vh-16\.9vw\)\] {
        height: calc(82.5vh - 16.9vw);
}
.h-auto {
        height: auto;
}
.h-fit {
        height: fit-content;
}
.h-full {
        height: 100%;
}
.h-screen {
        height: 100vh;
}
.max-h-775 {
        max-height: 103.333vw;
}
.max-h-\[300vh\] {
        max-height: 300vh;
}
.max-h-\[60vw\] {
        max-height: 60vw;
}
.max-h-\[75vw\] {
        max-height: 75vw;
}
.min-h-100 {
        min-height: 13.333vw;
}
.min-h-466 {
        min-height: 62.133vw;
}
.min-h-607 {
        min-height: 80.933vw;
}
.w-0 {
        width: 0px;
}
.w-1 {
        width: 0.133vw;
}
.w-1\/2 {
        width: 50%;
}
.w-104 {
        width: 13.867vw;
}
.w-110 {
        width: 14.667vw;
}
.w-12 {
        width: 1.600vw;
}
.w-120 {
        width: 16.000vw;
}
.w-130 {
        width: 17.333vw;
}
.w-132 {
        width: 17.600vw;
}
.w-134 {
        width: 17.867vw;
}
.w-14 {
        width: 1.867vw;
}
.w-142 {
        width: 18.933vw;
}
.w-152 {
        width: 20.267vw;
}
.w-156 {
        width: 20.800vw;
}
.w-16 {
        width: 2.133vw;
}
.w-160 {
        width: 21.333vw;
}
.w-164 {
        width: 21.867vw;
}
.w-17 {
        width: 2.267vw;
}
.w-18 {
        width: 2.400vw;
}
.w-180 {
        width: 24.000vw;
}
.w-182 {
        width: 24.267vw;
}
.w-187 {
        width: 24.933vw;
}
.w-188 {
        width: 25.067vw;
}
.w-194 {
        width: 25.867vw;
}
.w-2 {
        width: 0.267vw;
}
.w-200 {
        width: 26.667vw;
}
.w-208 {
        width: 27.733vw;
}
.w-220 {
        width: 29.333vw;
}
.w-223 {
        width: 29.733vw;
}
.w-230 {
        width: 30.667vw;
}
.w-24 {
        width: 3.200vw;
}
.w-242 {
        width: 32.267vw;
}
.w-251 {
        width: 33.467vw;
}
.w-252 {
        width: 33.600vw;
}
.w-256 {
        width: 34.133vw;
}
.w-26 {
        width: 3.467vw;
}
.w-276 {
        width: 36.800vw;
}
.w-278 {
        width: 37.067vw;
}
.w-292 {
        width: 38.933vw;
}
.w-302 {
        width: 40.267vw;
}
.w-308 {
        width: 41.067vw;
}
.w-315 {
        width: 42.000vw;
}
.w-32 {
        width: 4.267vw;
}
.w-320 {
        width: 42.667vw;
}
.w-330 {
        width: 44.000vw;
}
.w-34 {
        width: 4.533vw;
}
.w-35 {
        width: 4.667vw;
}
.w-350 {
        width: 46.667vw;
}
.w-356 {
        width: 47.467vw;
}
.w-360 {
        width: 48.000vw;
}
.w-38 {
        width: 5.067vw;
}
.w-390 {
        width: 52.000vw;
}
.w-40 {
        width: 5.333vw;
}
.w-410 {
        width: 54.667vw;
}
.w-426 {
        width: 56.800vw;
}
.w-438 {
        width: 58.400vw;
}
.w-440 {
        width: 58.667vw;
}
.w-446 {
        width: 59.467vw;
}
.w-450 {
        width: 60.000vw;
}
.w-464 {
        width: 61.867vw;
}
.w-478 {
        width: 63.733vw;
}
.w-48 {
        width: 6.400vw;
}
.w-50 {
        width: 6.667vw;
}
.w-52 {
        width: 6.933vw;
}
.w-546 {
        width: 72.800vw;
}
.w-56 {
        width: 7.467vw;
}
.w-568 {
        width: 75.733vw;
}
.w-590 {
        width: 78.667vw;
}
.w-598 {
        width: 79.733vw;
}
.w-6 {
        width: 0.800vw;
}
.w-60 {
        width: 8.000vw;
}
.w-606 {
        width: 80.800vw;
}
.w-610 {
        width: 81.333vw;
}
.w-630 {
        width: 84.000vw;
}
.w-654 {
        width: 87.200vw;
}
.w-662 {
        width: 88.267vw;
}
.w-678 {
        width: 90.400vw;
}
.w-702 {
        width: 93.600vw;
}
.w-71 {
        width: 9.467vw;
}
.w-72 {
        width: 9.600vw;
}
.w-76 {
        width: 10.133vw;
}
.w-80 {
        width: 10.667vw;
}
.w-84 {
        width: 11.200vw;
}
.w-85 {
        width: 11.333vw;
}
.w-88 {
        width: 11.733vw;
}
.w-92 {
        width: 12.267vw;
}
.w-\[1\.6vw\] {
        width: 1.6vw;
}
.w-\[100vh\] {
        width: 100vh;
}
.w-\[100vw\] {
        width: 100vw;
}
.w-\[11\.37vw\] {
        width: 11.37vw;
}
.w-\[12\.8vw\] {
        width: 12.8vw;
}
.w-\[120px\] {
        width: 120px;
}
.w-\[13\.6vw\] {
        width: 13.6vw;
}
.w-\[17\.6vw\] {
        width: 17.6vw;
}
.w-\[18\.6vw\] {
        width: 18.6vw;
}
.w-\[21\.2vw\] {
        width: 21.2vw;
}
.w-\[24vw\] {
        width: 24vw;
}
.w-\[26\.66vw\] {
        width: 26.66vw;
}
.w-\[28\.53vw\] {
        width: 28.53vw;
}
.w-\[28vw\] {
        width: 28vw;
}
.w-\[29\.33vw\] {
        width: 29.33vw;
}
.w-\[30\.667vw\] {
        width: 30.667vw;
}
.w-\[40vw\] {
        width: 40vw;
}
.w-\[44\.933vw\] {
        width: 44.933vw;
}
.w-\[44vw\] {
        width: 44vw;
}
.w-\[5\.067vw\] {
        width: 5.067vw;
}
.w-\[58\.4vw\] {
        width: 58.4vw;
}
.w-\[65vw\] {
        width: 65vw;
}
.w-\[71\.4vw\] {
        width: 71.4vw;
}
.w-\[72vw\] {
        width: 72vw;
}
.w-\[84vw\] {
        width: 84vw;
}
.w-\[9\.33vw\] {
        width: 9.33vw;
}
.w-\[92vh\] {
        width: 92vh;
}
.w-\[96vh\] {
        width: 96vh;
}
.w-fit {
        width: fit-content;
}
.w-full {
        width: 100%;
}
.w-screen {
        width: 100vw;
}
.min-w-2 {
        min-width: 0.267vw;
}
.max-w-2 {
        max-width: 0.267vw;
}
.max-w-280 {
        max-width: 37.333vw;
}
.max-w-360 {
        max-width: 48.000vw;
}
.max-w-\[76vw\] {
        max-width: 76vw;
}
.max-w-\[84vw\] {
        max-width: 84vw;
}
.flex-1 {
        flex: 1 1 0%;
}
.flex-shrink-0 {
        flex-shrink: 0;
}
.shrink-0 {
        flex-shrink: 0;
}
.flex-grow {
        flex-grow: 1;
}
.grow {
        flex-grow: 1;
}
.-translate-y-5 {
        --tw-translate-y: -0.667vw;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-full {
        --tw-translate-y: -100%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-16 {
        --tw-translate-x: 2.133vw;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[0\.5vw\] {
        --tw-translate-x: 0.5vw;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[100\%\] {
        --tw-translate-x: 100%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-full {
        --tw-translate-y: 100%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90 {
        --tw-rotate: -90deg;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
        --tw-rotate: 180deg;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
        --tw-rotate: 90deg;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\!transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes ping-custom {
        0% {
                transform: scale(0);
                opacity: 1;
        }
        100% {
                transform: scale(1);
                opacity: 0;
        }
}
.animate-ping-2 {
        animation: ping-custom 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes pulse {
        50% {
                opacity: .5;
        }
}
.animate-pulse {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-pointer {
        cursor: pointer;
}
.touch-none {
        touch-action: none;
}
.select-none {
        user-select: none;
}
.resize-none {
        resize: none;
}
.resize {
        resize: both;
}
.snap-center {
        scroll-snap-align: center;
}
.grid-flow-col {
        grid-auto-flow: column;
}
.grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-rows-2 {
        grid-template-rows: repeat(2, minmax(0, 1fr));
}
.\!flex-row {
        flex-direction: row !important;
}
.flex-row {
        flex-direction: row;
}
.flex-col {
        flex-direction: column;
}
.flex-wrap {
        flex-wrap: wrap;
}
.flex-nowrap {
        flex-wrap: nowrap;
}
.content-start {
        align-content: flex-start;
}
.items-start {
        align-items: flex-start;
}
.items-end {
        align-items: flex-end;
}
.items-center {
        align-items: center;
}
.items-baseline {
        align-items: baseline;
}
.\!justify-start {
        justify-content: flex-start !important;
}
.justify-start {
        justify-content: flex-start;
}
.justify-end {
        justify-content: flex-end;
}
.justify-center {
        justify-content: center;
}
.justify-between {
        justify-content: space-between;
}
.gap-0 {
        gap: 0px;
}
.gap-1 {
        gap: 0.133vw;
}
.gap-10 {
        gap: 1.333vw;
}
.gap-12 {
        gap: 1.600vw;
}
.gap-13 {
        gap: 1.733vw;
}
.gap-14 {
        gap: 1.867vw;
}
.gap-16 {
        gap: 2.133vw;
}
.gap-18 {
        gap: 2.400vw;
}
.gap-2 {
        gap: 0.267vw;
}
.gap-24 {
        gap: 3.200vw;
}
.gap-26 {
        gap: 3.467vw;
}
.gap-32 {
        gap: 4.267vw;
}
.gap-4 {
        gap: 0.533vw;
}
.gap-48 {
        gap: 6.400vw;
}
.gap-5 {
        gap: 0.667vw;
}
.gap-6 {
        gap: 0.800vw;
}
.gap-64 {
        gap: 8.533vw;
}
.gap-7 {
        gap: 0.933vw;
}
.gap-8 {
        gap: 1.067vw;
}
.gap-x-14 {
        column-gap: 1.867vw;
}
.gap-x-16 {
        column-gap: 2.133vw;
}
.gap-x-18 {
        column-gap: 2.400vw;
}
.gap-y-14 {
        row-gap: 1.867vw;
}
.gap-y-20 {
        row-gap: 2.667vw;
}
.gap-y-24 {
        row-gap: 3.200vw;
}
.self-start {
        align-self: flex-start;
}
.self-end {
        align-self: flex-end;
}
.self-center {
        align-self: center;
}
.self-stretch {
        align-self: stretch;
}
.overflow-hidden {
        overflow: hidden;
}
.overflow-visible {
        overflow: visible;
}
.overflow-x-auto {
        overflow-x: auto;
}
.overflow-y-auto {
        overflow-y: auto;
}
.overflow-x-hidden {
        overflow-x: hidden;
}
.overflow-y-hidden {
        overflow-y: hidden;
}
.overflow-x-scroll {
        overflow-x: scroll;
}
.overflow-y-scroll {
        overflow-y: scroll;
}
.overscroll-y-contain {
        overscroll-behavior-y: contain;
}
.truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
}
.overflow-ellipsis {
        text-overflow: ellipsis;
}
.text-ellipsis {
        text-overflow: ellipsis;
}
.whitespace-normal {
        white-space: normal;
}
.whitespace-nowrap {
        white-space: nowrap;
}
.whitespace-pre-line {
        white-space: pre-line;
}
.text-nowrap {
        text-wrap: nowrap;
}
.text-balance {
        text-wrap: balance;
}
.break-normal {
        overflow-wrap: normal;
        word-break: normal;
}
.rounded-\[4\.267vw\] {
        border-radius: 4.267vw;
}
.rounded-\[5\.333vw\] {
        border-radius: 5.333vw;
}
.rounded-\[50\%\] {
        border-radius: 50%;
}
.rounded-custom {
        border-radius: 13.333vw 6.667vw 6.667vw 13.333vw;
}
.rounded-full {
        border-radius: 9999px;
}
.rounded-r12 {
        border-radius: 1.600vw;
}
.rounded-r16 {
        border-radius: 2.133vw;
}
.rounded-r24 {
        border-radius: 3.200vw;
}
.rounded-r30 {
        border-radius: 4.000vw;
}
.rounded-r32 {
        border-radius: 4.267vw;
}
.rounded-r4 {
        border-radius: 0.533vw;
}
.rounded-r40 {
        border-radius: 5.333vw;
}
.rounded-r6 {
        border-radius: 0.800vw;
}
.rounded-r65 {
        border-radius: 8.667vw;
}
.rounded-r8 {
        border-radius: 1.067vw;
}
.rounded-b-r32 {
        border-bottom-right-radius: 4.267vw;
        border-bottom-left-radius: 4.267vw;
}
.rounded-b-r40 {
        border-bottom-right-radius: 5.333vw;
        border-bottom-left-radius: 5.333vw;
}
.rounded-l-r4 {
        border-top-left-radius: 0.533vw;
        border-bottom-left-radius: 0.533vw;
}
.rounded-l-r8 {
        border-top-left-radius: 1.067vw;
        border-bottom-left-radius: 1.067vw;
}
.rounded-r-full {
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
}
.rounded-r-r4 {
        border-top-right-radius: 0.533vw;
        border-bottom-right-radius: 0.533vw;
}
.rounded-r-r8 {
        border-top-right-radius: 1.067vw;
        border-bottom-right-radius: 1.067vw;
}
.rounded-t-r24 {
        border-top-left-radius: 3.200vw;
        border-top-right-radius: 3.200vw;
}
.rounded-t-r40 {
        border-top-left-radius: 5.333vw;
        border-top-right-radius: 5.333vw;
}
.rounded-t-r48 {
        border-top-left-radius: 6.400vw;
        border-top-right-radius: 6.400vw;
}
.rounded-bl-r24 {
        border-bottom-left-radius: 3.200vw;
}
.rounded-br-r16 {
        border-bottom-right-radius: 2.133vw;
}
.rounded-tl-r24 {
        border-top-left-radius: 3.200vw;
}
.rounded-tl-r40 {
        border-top-left-radius: 5.333vw;
}
.rounded-tr-r16 {
        border-top-right-radius: 2.133vw;
}
.rounded-tr-r40 {
        border-top-right-radius: 5.333vw;
}
.border-0 {
        border-width: 0px;
}
.border-1 {
        border-width: 0.133vw;
}
.border-2 {
        border-width: 0.267vw;
}
.border-4 {
        border-width: 0.533vw;
}
.border-\[0\.133vw\] {
        border-width: 0.133vw;
}
.border-\[0\.533vw\] {
        border-width: 0.533vw;
}
.border-x-0 {
        border-left-width: 0px;
        border-right-width: 0px;
}
.border-b-1 {
        border-bottom-width: 0.133vw;
}
.border-b-\[0\.133vw\] {
        border-bottom-width: 0.133vw;
}
.border-l-0 {
        border-left-width: 0px;
}
.border-l-2 {
        border-left-width: 0.267vw;
}
.border-r-0 {
        border-right-width: 0px;
}
.border-t-0 {
        border-top-width: 0px;
}
.border-t-\[0\.133vw\] {
        border-top-width: 0.133vw;
}
.border-solid {
        border-style: solid;
}
.border-dashed {
        border-style: dashed;
}
.border-none {
        border-style: none;
}
.border-\[\#E7EDFF\] {
        --tw-border-opacity: 1;
        border-color: rgb(231 237 255 / var(--tw-border-opacity, 1));
}
.border-\[\#FE4B50\] {
        --tw-border-opacity: 1;
        border-color: rgb(254 75 80 / var(--tw-border-opacity, 1));
}
.border-\[rgba\(251\2c 71\2c 93\2c 0\.12\)\] {
        border-color: rgba(251,71,93,0.12);
}
.border-\[rgba\(252\2c 206\2c 162\2c 0\.4\)\] {
        border-color: rgba(252,206,162,0.4);
}
.border-\[rgba\(255\2c 127\2c 91\2c 0\.30\)\] {
        border-color: rgba(255,127,91,0.30);
}
.border-badge_border_hot_blue {
        border-color: #6D8FFD4D;
}
.border-badge_green {
        border-color: #00B9851F;
}
.border-bg_primary_blue {
        border-color: var(--bg-primary-blue);
}
.border-border_blue {
        border-color: #447EFF4D;
}
.border-border_button_gray {
        border-color: var(--boder-button-gray);
}
.border-divider_gray {
        border-color: var(--divider-gray);
}
.border-error_red {
        border-color: #FB475D1F;
}
.border-gray_2 {
        border-color: var(--gray-2);
}
.border-green {
        --tw-border-opacity: 1;
        border-color: rgb(0 185 133 / var(--tw-border-opacity, 1));
}
.border-modal_bg_blue {
        border-color: var(--modal-bg-blue);
}
.border-modal_indicator_gray {
        border-color: var(--modal-indicator-gray);
}
.border-orange {
        --tw-border-opacity: 1;
        border-color: rgb(255 127 91 / var(--tw-border-opacity, 1));
}
.border-red {
        --tw-border-opacity: 1;
        border-color: rgb(251 71 93 / var(--tw-border-opacity, 1));
}
.border-white {
        --tw-border-opacity: 1;
        border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.\!bg-bg_page {
        background-color: var(--bg-page) !important;
}
.bg-\[\#AD855F\] {
        --tw-bg-opacity: 1;
        background-color: rgb(173 133 95 / var(--tw-bg-opacity, 1));
}
.bg-\[\#E7EDFF\] {
        --tw-bg-opacity: 1;
        background-color: rgb(231 237 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#F0F3F8\] {
        --tw-bg-opacity: 1;
        background-color: rgb(240 243 248 / var(--tw-bg-opacity, 1));
}
.bg-\[\#F5F8FF\] {
        --tw-bg-opacity: 1;
        background-color: rgb(245 248 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FF7F5B1F\] {
        background-color: #FF7F5B1F;
}
.bg-\[\#FFE6B0\] {
        --tw-bg-opacity: 1;
        background-color: rgb(255 230 176 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FFEFDB\]\/80 {
        background-color: rgb(255 239 219 / 0.8);
}
.bg-\[\#FFF\]\/60 {
        background-color: rgb(255 255 255 / 0.6);
}
.bg-\[\#f5f8ff\] {
        --tw-bg-opacity: 1;
        background-color: rgb(245 248 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#fdf5e4\] {
        --tw-bg-opacity: 1;
        background-color: rgb(253 245 228 / var(--tw-bg-opacity, 1));
}
.bg-\[\#ffffff\] {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-\[rgb\(57\2c 123\2c 246\)\] {
        --tw-bg-opacity: 1;
        background-color: rgb(57 123 246 / var(--tw-bg-opacity, 1));
}
.bg-\[rgba\(219\2c 184\2c 154\2c 0\.10\)\] {
        background-color: rgba(219,184,154,0.10);
}
.bg-\[rgba\(255\2c 165\2c 112\2c 0\.17\)\] {
        background-color: rgba(255,165,112,0.17);
}
.bg-\[rgba\(255\2c 239\2c 219\2c 0\.80\)\] {
        background-color: rgba(255,239,219,0.80);
}
.bg-\[transparent\] {
        background-color: transparent;
}
.bg-background_datapickter_hot {
        background-color: var(--background-datapickter-hot);
}
.bg-badge_blue {
        background-color: #447EFF1F;
}
.bg-badge_green {
        background-color: #00B9851F;
}
.bg-badge_hot_blue {
        --tw-bg-opacity: 1;
        background-color: rgb(109 143 253 / var(--tw-bg-opacity, 1));
}
.bg-badge_hot_blue_1 {
        background-color: #6D8FFD1F;
}
.bg-badge_lake_green {
        background-color: #46DCE41F;
}
.bg-badge_orange {
        background-color: #FF7F5B1F;
}
.bg-badge_purple {
        background-color: #5E5BFF1F;
}
.bg-badge_rose_red {
        background-color: #FD50971F;
}
.bg-badge_sky_blue {
        background-color: #1CC9FF1F;
}
.bg-badge_yellow {
        background-color: #FF96261F;
}
.bg-bg_page {
        background-color: var(--bg-page);
}
.bg-bg_primary_blue {
        background-color: var(--bg-primary-blue);
}
.bg-bg_red {
        background-color: #FB475D0A;
}
.bg-blue_fill {
        background-color: var(--icon-hot-blue-fill);
}
.bg-border_button_gray {
        background-color: var(--boder-button-gray);
}
.bg-card_bg_white {
        background-color: var(--card-bg-white);
}
.bg-card_bg_white_card {
        background-color: var(--card-bg-white-card);
}
.bg-divider_gray {
        background-color: var(--divider-gray);
}
.bg-error_red {
        background-color: #FB475D1F;
}
.bg-gray_1 {
        background-color: var(--gray-1);
}
.bg-gray_2 {
        background-color: var(--gray-2);
}
.bg-green {
        --tw-bg-opacity: 1;
        background-color: rgb(0 185 133 / var(--tw-bg-opacity, 1));
}
.bg-icon_orange {
        background-color: #FF7F5B80;
}
.bg-lake_green {
        --tw-bg-opacity: 1;
        background-color: rgb(70 220 228 / var(--tw-bg-opacity, 1));
}
.bg-mask_black {
        background-color: #00000080;
}
.bg-modal_bg_blue {
        background-color: var(--modal-bg-blue);
}
.bg-modal_bg_input_blue {
        background-color: var(--modal-bg-input-blue);
}
.bg-modal_indicator_gray {
        background-color: var(--modal-indicator-gray);
}
.bg-modal_tab_selected_orange {
        background-color: var(--modal-tab-selected-orange);
}
.bg-modal_white {
        background-color: var(--modal-white);
}
.bg-orange {
        --tw-bg-opacity: 1;
        background-color: rgb(255 127 91 / var(--tw-bg-opacity, 1));
}
.bg-purple {
        --tw-bg-opacity: 1;
        background-color: rgb(94 91 255 / var(--tw-bg-opacity, 1));
}
.bg-red {
        --tw-bg-opacity: 1;
        background-color: rgb(251 71 93 / var(--tw-bg-opacity, 1));
}
.bg-rose_red {
        --tw-bg-opacity: 1;
        background-color: rgb(253 80 151 / var(--tw-bg-opacity, 1));
}
.bg-sky_blue {
        --tw-bg-opacity: 1;
        background-color: rgb(28 201 255 / var(--tw-bg-opacity, 1));
}
.bg-tab_divider_gray {
        background-color: var(--tab-divider-gray);
}
.bg-tab_selected_white {
        background-color: var(--tab-selected-white);
}
.bg-tab_white {
        background-color: var(--tab-white);
}
.bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-gradient-to-b {
        background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
        background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-\[\#4B3013\] {
        --tw-gradient-from: #4B3013 var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(75 48 19 / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[rgba\(0\2c 185\2c 133\2c 0\.12\)\] {
        --tw-gradient-from: rgba(0,185,133,0.12) var(--tw-gradient-from-position);
        --tw-gradient-to: rgba(0, 185, 133, 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[rgba\(251\2c 71\2c 93\2c 0\.12\)\] {
        --tw-gradient-from: rgba(251,71,93,0.12) var(--tw-gradient-from-position);
        --tw-gradient-to: rgba(251, 71, 93, 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[rgba\(255\2c 246\2c 234\2c 1\)\] {
        --tw-gradient-from: rgba(255,246,234,1) var(--tw-gradient-from-position);
        --tw-gradient-to: rgba(255, 246, 234, 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-\[\#1F150E\] {
        --tw-gradient-to: #1F150E var(--tw-gradient-to-position);
}
.to-\[rgba\(0\2c 185\2c 133\2c 0\.03\)\] {
        --tw-gradient-to: rgba(0,185,133,0.03) var(--tw-gradient-to-position);
}
.to-\[rgba\(251\2c 71\2c 93\2c 0\.03\)\] {
        --tw-gradient-to: rgba(251,71,93,0.03) var(--tw-gradient-to-position);
}
.to-\[rgba\(255\2c 246\2c 234\2c 0\)\] {
        --tw-gradient-to: rgba(255,246,234,0) var(--tw-gradient-to-position);
}
.bg-contain {
        background-size: contain;
}
.bg-cover {
        background-size: cover;
}
.bg-clip-text {
        background-clip: text;
}
.bg-no-repeat {
        background-repeat: no-repeat;
}
.stroke-2 {
        stroke-width: 2;
}
.p-1 {
        padding: 0.133vw;
}
.p-16 {
        padding: 2.133vw;
}
.p-24 {
        padding: 3.200vw;
}
.p-3 {
        padding: 0.400vw;
}
.p-32 {
        padding: 4.267vw;
}
.p-4 {
        padding: 0.533vw;
}
.p-48 {
        padding: 6.400vw;
}
.p-8 {
        padding: 1.067vw;
}
.px-0 {
        padding-left: 0px;
        padding-right: 0px;
}
.px-10 {
        padding-left: 1.333vw;
        padding-right: 1.333vw;
}
.px-12 {
        padding-left: 1.600vw;
        padding-right: 1.600vw;
}
.px-16 {
        padding-left: 2.133vw;
        padding-right: 2.133vw;
}
.px-20 {
        padding-left: 2.667vw;
        padding-right: 2.667vw;
}
.px-24 {
        padding-left: 3.200vw;
        padding-right: 3.200vw;
}
.px-32 {
        padding-left: 4.267vw;
        padding-right: 4.267vw;
}
.px-36 {
        padding-left: 4.800vw;
        padding-right: 4.800vw;
}
.px-4 {
        padding-left: 0.533vw;
        padding-right: 0.533vw;
}
.px-40 {
        padding-left: 5.333vw;
        padding-right: 5.333vw;
}
.px-48 {
        padding-left: 6.400vw;
        padding-right: 6.400vw;
}
.px-56 {
        padding-left: 7.467vw;
        padding-right: 7.467vw;
}
.px-64 {
        padding-left: 8.533vw;
        padding-right: 8.533vw;
}
.px-8 {
        padding-left: 1.067vw;
        padding-right: 1.067vw;
}
.px-\[3\.2vw\] {
        padding-left: 3.2vw;
        padding-right: 3.2vw;
}
.px-\[4\.8vw\] {
        padding-left: 4.8vw;
        padding-right: 4.8vw;
}
.px-\[6\.4vw\] {
        padding-left: 6.4vw;
        padding-right: 6.4vw;
}
.px-\[8vw\] {
        padding-left: 8vw;
        padding-right: 8vw;
}
.py-10 {
        padding-top: 1.333vw;
        padding-bottom: 1.333vw;
}
.py-12 {
        padding-top: 1.600vw;
        padding-bottom: 1.600vw;
}
.py-16 {
        padding-top: 2.133vw;
        padding-bottom: 2.133vw;
}
.py-2 {
        padding-top: 0.267vw;
        padding-bottom: 0.267vw;
}
.py-24 {
        padding-top: 3.200vw;
        padding-bottom: 3.200vw;
}
.py-28 {
        padding-top: 3.733vw;
        padding-bottom: 3.733vw;
}
.py-32 {
        padding-top: 4.267vw;
        padding-bottom: 4.267vw;
}
.py-4 {
        padding-top: 0.533vw;
        padding-bottom: 0.533vw;
}
.py-40 {
        padding-top: 5.333vw;
        padding-bottom: 5.333vw;
}
.py-42 {
        padding-top: 5.600vw;
        padding-bottom: 5.600vw;
}
.py-6 {
        padding-top: 0.800vw;
        padding-bottom: 0.800vw;
}
.py-8 {
        padding-top: 1.067vw;
        padding-bottom: 1.067vw;
}
.py-\[12px\] {
        padding-top: 12px;
        padding-bottom: 12px;
}
.py-\[1vw\] {
        padding-top: 1vw;
        padding-bottom: 1vw;
}
.py-\[2\.133vw\] {
        padding-top: 2.133vw;
        padding-bottom: 2.133vw;
}
.py-\[3\.2vw\] {
        padding-top: 3.2vw;
        padding-bottom: 3.2vw;
}
.py-\[4\.267vw\] {
        padding-top: 4.267vw;
        padding-bottom: 4.267vw;
}
.pb-0 {
        padding-bottom: 0px;
}
.pb-12 {
        padding-bottom: 1.600vw;
}
.pb-140 {
        padding-bottom: 18.667vw;
}
.pb-16 {
        padding-bottom: 2.133vw;
}
.pb-20 {
        padding-bottom: 2.667vw;
}
.pb-24 {
        padding-bottom: 3.200vw;
}
.pb-270 {
        padding-bottom: 36.000vw;
}
.pb-32 {
        padding-bottom: 4.267vw;
}
.pb-4 {
        padding-bottom: 0.533vw;
}
.pb-40 {
        padding-bottom: 5.333vw;
}
.pb-48 {
        padding-bottom: 6.400vw;
}
.pb-5 {
        padding-bottom: 0.667vw;
}
.pb-50 {
        padding-bottom: 6.667vw;
}
.pb-68 {
        padding-bottom: 9.067vw;
}
.pb-75 {
        padding-bottom: 10.000vw;
}
.pb-8 {
        padding-bottom: 1.067vw;
}
.pb-90 {
        padding-bottom: 12.000vw;
}
.pb-\[20vh\] {
        padding-bottom: 20vh;
}
.pb-\[4vw\] {
        padding-bottom: 4vw;
}
.pl-10 {
        padding-left: 1.333vw;
}
.pl-100 {
        padding-left: 13.333vw;
}
.pl-12 {
        padding-left: 1.600vw;
}
.pl-16 {
        padding-left: 2.133vw;
}
.pl-24 {
        padding-left: 3.200vw;
}
.pl-30 {
        padding-left: 4.000vw;
}
.pl-32 {
        padding-left: 4.267vw;
}
.pl-35 {
        padding-left: 4.667vw;
}
.pl-36 {
        padding-left: 4.800vw;
}
.pl-48 {
        padding-left: 6.400vw;
}
.pl-8 {
        padding-left: 1.067vw;
}
.pl-80 {
        padding-left: 10.667vw;
}
.pl-\[4\.8vw\] {
        padding-left: 4.8vw;
}
.pr-10 {
        padding-right: 1.333vw;
}
.pr-100 {
        padding-right: 13.333vw;
}
.pr-12 {
        padding-right: 1.600vw;
}
.pr-16 {
        padding-right: 2.133vw;
}
.pr-24 {
        padding-right: 3.200vw;
}
.pr-32 {
        padding-right: 4.267vw;
}
.pr-36 {
        padding-right: 4.800vw;
}
.pr-48 {
        padding-right: 6.400vw;
}
.pr-8 {
        padding-right: 1.067vw;
}
.pr-\[4\.8vw\] {
        padding-right: 4.8vw;
}
.pt-0 {
        padding-top: 0px;
}
.pt-12 {
        padding-top: 1.600vw;
}
.pt-16 {
        padding-top: 2.133vw;
}
.pt-200 {
        padding-top: 26.667vw;
}
.pt-24 {
        padding-top: 3.200vw;
}
.pt-32 {
        padding-top: 4.267vw;
}
.pt-36 {
        padding-top: 4.800vw;
}
.pt-4 {
        padding-top: 0.533vw;
}
.pt-40 {
        padding-top: 5.333vw;
}
.pt-44 {
        padding-top: 5.867vw;
}
.pt-48 {
        padding-top: 6.400vw;
}
.pt-66 {
        padding-top: 8.800vw;
}
.pt-75 {
        padding-top: 10.000vw;
}
.pt-8 {
        padding-top: 1.067vw;
}
.pt-96 {
        padding-top: 12.800vw;
}
.pt-\[18vw\] {
        padding-top: 18vw;
}
.pt-\[4\.267vw\] {
        padding-top: 4.267vw;
}
.text-center {
        text-align: center;
}
.text-right {
        text-align: right;
}
.text-justify {
        text-align: justify;
}
.align-top {
        vertical-align: top;
}
.align-middle {
        vertical-align: middle;
}
.align-\[0\.2vw\] {
        vertical-align: 0.2vw;
}
.font-\[\'DIN\'\] {
        font-family: 'DIN';
}
.font-\[DIN\] {
        font-family: DIN;
}
.font-hello {
        font-family: HelloFont-WenYiHei, HelloFont, sans-serif;
}
.font-number {
        font-family: MiNum, MiSans, sans-serif;
}
.font-sans {
        font-family: MiSans, sans-serif;
}
.text-\[15px\] {
        font-size: 15px;
}
.text-\[2\.667vw\] {
        font-size: 2.667vw;
}
.text-\[20px\] {
        font-size: 20px;
}
.text-\[2vw\] {
        font-size: 2vw;
}
.text-\[3\.4vw\] {
        font-size: 3.4vw;
}
.text-\[3\.733vw\] {
        font-size: 3.733vw;
}
.text-\[4\.267vw\] {
        font-size: 4.267vw;
}
.text-\[4\.8vw\] {
        font-size: 4.8vw;
}
.text-\[4vw\] {
        font-size: 4vw;
}
.text-\[5\.3vw\] {
        font-size: 5.3vw;
}
.text-\[8\.53333vw\] {
        font-size: 8.53333vw;
}
.text-content_title {
        font-size: 5.867vw;
        line-height: 7.733vw;
        font-weight: 700;
}
.text-index_selected {
        font-size: 3.733vw;
        line-height: 5.067vw;
        font-weight: 700;
}
.text-index_unselected {
        font-size: 3.733vw;
        line-height: 5.067vw;
        font-weight: 400;
}
.text-list_name {
        font-size: 4.267vw;
        line-height: 5.067vw;
        font-weight: 500;
}
.text-max_title {
        font-size: 6.400vw;
        line-height: 9.067vw;
        font-weight: 500;
}
.text-modal_button {
        font-size: 3.733vw;
        line-height: 5.067vw;
        font-weight: 500;
}
.text-modal_button2 {
        font-size: 4.267vw;
        line-height: 5.067vw;
        font-weight: 400;
}
.text-modal_button3 {
        font-size: 4.800vw;
        line-height: 7.200vw;
        font-weight: 400;
}
.text-modal_content {
        font-size: 4.267vw;
        line-height: 6.400vw;
        font-weight: 400;
}
.text-modal_content1 {
        font-size: 4.267vw;
        line-height: 6.400vw;
        font-weight: 500;
}
.text-modal_list_name {
        font-size: 4.267vw;
        line-height: 5.067vw;
        font-weight: 700;
}
.text-modal_news {
        font-size: 3.733vw;
        line-height: 5.600vw;
        font-weight: 400;
}
.text-numPrice {
        font-size: 4.800vw;
        line-height: 7.200vw;
        font-weight: 400;
}
.text-number_code {
        font-size: 3.467vw;
        line-height: 5.067vw;
        font-weight: 400;
}
.text-number_large {
        font-size: 5.333vw;
        line-height: 5.333vw;
        font-weight: 400;
}
.text-number_middle {
        font-size: 4.267vw;
        line-height: 5.067vw;
        font-weight: 400;
}
.text-number_small {
        font-size: 3.733vw;
        line-height: 5.600vw;
        font-weight: 400;
}
.text-overSized {
        font-size: 9.600vw;
        line-height: 12.800vw;
        font-weight: 700;
}
.text-remark {
        font-size: 3.200vw;
        line-height: 4.267vw;
        font-weight: 400;
}
.text-small {
        font-size: 2.667vw;
        line-height: 2.667vw;
        font-weight: 400;
}
.text-strategy_details {
        font-size: 3.733vw;
        line-height: 5.067vw;
        font-weight: 400;
}
.text-sub_subtitle_selected {
        font-size: 3.467vw;
        line-height: 5.067vw;
        font-weight: 700;
}
.text-sub_subtitle_unselected {
        font-size: 3.467vw;
        line-height: 5.067vw;
        font-weight: 400;
}
.text-subtitle_strategy_name {
        font-size: 4.800vw;
        line-height: 6.667vw;
        font-weight: 500;
}
.text-title_number {
        font-size: 2.933vw;
        line-height: 3.200vw;
        font-weight: 500;
}
.text-title_selected {
        font-size: 5.333vw;
        line-height: 7.467vw;
        font-weight: 700;
}
.text-title_strategy_span {
        font-size: 3.467vw;
        line-height: 3.467vw;
        font-weight: 500;
}
.text-title_unselected {
        font-size: 5.333vw;
        line-height: 7.467vw;
        font-weight: 500;
}
.text-title_unselected1 {
        font-size: 5.333vw;
        line-height: 8.000vw;
        font-weight: 500;
}
.font-bold {
        font-weight: 700;
}
.font-medium {
        font-weight: 500;
}
.font-normal {
        font-weight: 400;
}
.italic {
        font-style: italic;
}
.tabular-nums {
        --tw-numeric-spacing: tabular-nums;
        font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.leading-\[1\.867vw\] {
        line-height: 1.867vw;
}
.leading-\[2\.667vw\] {
        line-height: 2.667vw;
}
.leading-\[4\.533vw\] {
        line-height: 4.533vw;
}
.leading-\[5\.067vw\] {
        line-height: 5.067vw;
}
.leading-\[6vw\] {
        line-height: 6vw;
}
.leading-\[7vw\] {
        line-height: 7vw;
}
.leading-\[8\.533vw\] {
        line-height: 8.533vw;
}
.-tracking-\[\.2vw\] {
        letter-spacing: -.2vw;
}
.tracking-\[-0\.2vw\] {
        letter-spacing: -0.2vw;
}
.tracking-\[0\.213vw\] {
        letter-spacing: 0.213vw;
}
.\!text-black {
        color: var(--black) !important;
}
.text-\[\#333\] {
        --tw-text-opacity: 1;
        color: rgb(51 51 51 / var(--tw-text-opacity, 1));
}
.text-\[\#593712\] {
        --tw-text-opacity: 1;
        color: rgb(89 55 18 / var(--tw-text-opacity, 1));
}
.text-\[\#6B748B\] {
        --tw-text-opacity: 1;
        color: rgb(107 116 139 / var(--tw-text-opacity, 1));
}
.text-\[\#73091E\] {
        --tw-text-opacity: 1;
        color: rgb(115 9 30 / var(--tw-text-opacity, 1));
}
.text-\[\#A0A5B3\] {
        --tw-text-opacity: 1;
        color: rgb(160 165 179 / var(--tw-text-opacity, 1));
}
.text-\[\#AD855F\] {
        --tw-text-opacity: 1;
        color: rgb(173 133 95 / var(--tw-text-opacity, 1));
}
.text-\[\#ADB4C6\] {
        --tw-text-opacity: 1;
        color: rgb(173 180 198 / var(--tw-text-opacity, 1));
}
.text-\[\#FB475D\] {
        --tw-text-opacity: 1;
        color: rgb(251 71 93 / var(--tw-text-opacity, 1));
}
.text-\[\#FE4B50\] {
        --tw-text-opacity: 1;
        color: rgb(254 75 80 / var(--tw-text-opacity, 1));
}
.text-\[black\] {
        --tw-text-opacity: 1;
        color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.text-\[rgba\(102\2c 102\2c 102\2c 1\)\] {
        color: rgba(102,102,102,1);
}
.text-\[rgba\(89\2c 55\2c 18\2c 0\.60\)\] {
        color: rgba(89,55,18,0.60);
}
.text-badge_hot_blue {
        --tw-text-opacity: 1;
        color: rgb(109 143 253 / var(--tw-text-opacity, 1));
}
.text-badge_orange {
        color: #FF7F5B1F;
}
.text-black {
        color: var(--black);
}
.text-blue {
        --tw-text-opacity: 1;
        color: rgb(68 126 255 / var(--tw-text-opacity, 1));
}
.text-brown {
        color: var(--brown);
}
.text-gray_1 {
        color: var(--gray-1);
}
.text-gray_2 {
        color: var(--gray-2);
}
.text-green {
        --tw-text-opacity: 1;
        color: rgb(0 185 133 / var(--tw-text-opacity, 1));
}
.text-lake_green {
        --tw-text-opacity: 1;
        color: rgb(70 220 228 / var(--tw-text-opacity, 1));
}
.text-modal_text_black {
        color: var(--modal-text-black);
}
.text-orange {
        --tw-text-opacity: 1;
        color: rgb(255 127 91 / var(--tw-text-opacity, 1));
}
.text-orange_fill {
        color: #FF7F5B66;
}
.text-purple {
        --tw-text-opacity: 1;
        color: rgb(94 91 255 / var(--tw-text-opacity, 1));
}
.text-red {
        --tw-text-opacity: 1;
        color: rgb(251 71 93 / var(--tw-text-opacity, 1));
}
.text-rose_red {
        --tw-text-opacity: 1;
        color: rgb(253 80 151 / var(--tw-text-opacity, 1));
}
.text-sky_blue {
        --tw-text-opacity: 1;
        color: rgb(28 201 255 / var(--tw-text-opacity, 1));
}
.text-text_disabled_gray {
        color: var(--text-disabled-gray);
}
.text-vip_text {
        color: var(--vip-text);
}
.text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.text-yellow {
        --tw-text-opacity: 1;
        color: rgb(255 150 38 / var(--tw-text-opacity, 1));
}
.underline {
        text-decoration-line: underline;
}
.overline {
        text-decoration-line: overline;
}
.line-through {
        text-decoration-line: line-through;
}
.no-underline {
        text-decoration-line: none;
}
.antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
.opacity-0 {
        opacity: 0;
}
.opacity-100 {
        opacity: 1;
}
.opacity-30 {
        opacity: 0.3;
}
.opacity-60 {
        opacity: 0.6;
}
.opacity-80 {
        opacity: 0.8;
}
.shadow {
        --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_-0\.8vw_1\.333vw_0_rgba\(173\2c 180\2c 198\2c 0\.1\)\] {
        --tw-shadow: 0 -0.8vw 1.333vw 0 rgba(173,180,198,0.1);
        --tw-shadow-colored: 0 -0.8vw 1.333vw 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_-0\.8vw_1\.333vw_0_rgba\(173\2c 180\2c 198\2c 0\.10\)\] {
        --tw-shadow: 0 -0.8vw 1.333vw 0 rgba(173,180,198,0.10);
        --tw-shadow-colored: 0 -0.8vw 1.333vw 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_-0\.8vw_1\.333vw_0_var\(--tab-shadow-gray\)\] {
        --tw-shadow: 0 -0.8vw 1.333vw 0 var(--tab-shadow-gray);
        --tw-shadow-colored: 0 -0.8vw 1.333vw 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_-6vw_10vw_0_var\(--tab-shadow-gray\)\] {
        --tw-shadow: 0 -6vw 10vw 0 var(--tab-shadow-gray);
        --tw-shadow-colored: 0 -6vw 10vw 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_0_0\.5vw_rgba\(255\2c 0\2c 0\2c 0\.5\)\] {
        --tw-shadow: 0 0 0.5vw rgba(255,0,0,0.5);
        --tw-shadow-colored: 0 0 0.5vw var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0vw_-0\.8vw_1\.33vw_0vw_\#ADB4C61A\] {
        --tw-shadow: 0vw -0.8vw 1.33vw 0vw #ADB4C61A;
        --tw-shadow-colored: 0vw -0.8vw 1.33vw 0vw var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px\2c 1px\2c 4px\2c 0px\2c rgba\(107\2c 116\2c 139\2c 0\.4\)\] {
        --tw-shadow-color: 0px,1px,4px,0px,rgba(107,116,139,0.4);
        --tw-shadow: var(--tw-shadow-colored);
}
.outline-none {
        outline: 2px solid transparent;
        outline-offset: 2px;
}
.outline {
        outline-style: solid;
}
.ring {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.blur {
        --tw-blur: blur(8px);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-\[0_0_0\.53vw_rgba\(0\2c 0\2c 0\2c 0\.02\)\] {
        --tw-drop-shadow: drop-shadow(0 0 0.53vw rgba(0,0,0,0.02));
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
        --tw-grayscale: grayscale(100%);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
        --tw-invert: invert(100%);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-\[1\.2vw\] {
        --tw-backdrop-blur: blur(1.2vw);
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.transition-all {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.delay-200 {
        transition-delay: 200ms;
}
.duration-200 {
        transition-duration: 200ms;
}
.duration-300 {
        transition-duration: 300ms;
}
.ease-in-out {
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@font-face {
    font-family: "MiNum";
    src: url("/static/MiSans-Regular-num.woff2");
    font-weight: 400;
}

@font-face {
    font-family: "MiNum";
    src: url("/static/MiSans-Medium-num.woff2");
    font-weight: 500;
}

@font-face {
    font-family: "MiNum";
    src: url("/static/MiSans-Demibold-num.woff2");
    font-weight: 700;
}


@font-face {
    font-family: "MiSans";
    src: url("/static/MiSans-Regular-lite.woff2");
    font-weight: normal;
}

@font-face {
    font-family: "MiSans";
    src: url("/static/MiSans-Medium-lite.woff2");
    font-weight: 500;
}

@font-face {
    font-family: "MiSans";
    src: url("/static/MiSans-Demibold-lite.woff2");
    font-weight: bold;
}

body {
    margin: 0;
    font-family: 'MiSans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

/*ios input 带边框的问题*/
input {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
    border: none;
}

/* *, ::before, ::after {
    --tw-border-spacing-x:;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:;
    --tw-pan-y:;
    --tw-pinch-zoom:;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:;
    --tw-gradient-via-position:;
    --tw-gradient-to-position:;
    --tw-ordinal:;
    --tw-slashed-zero:;
    --tw-numeric-figure:;
    --tw-numeric-spacing:;
    --tw-numeric-fraction:;
    --tw-ring-inset:;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:;
    --tw-brightness:;
    --tw-contrast:;
    --tw-grayscale:;
    --tw-hue-rotate:;
    --tw-invert:;
    --tw-saturate:;
    --tw-sepia:;
    --tw-drop-shadow:;
    --tw-backdrop-blur:;
    --tw-backdrop-brightness:;
    --tw-backdrop-contrast:;
    --tw-backdrop-grayscale:;
    --tw-backdrop-hue-rotate:;
    --tw-backdrop-invert:;
    --tw-backdrop-opacity:;
    --tw-backdrop-saturate:;
    --tw-backdrop-sepia:;
    --tw-contain-size:;
    --tw-contain-layout:;
    --tw-contain-paint:;
    --tw-contain-style:;
} */

.\*\:border-border_icon_hot_blue > * {
        border-color: #6D8FFD4D;
}

.\*\:text-gray_1 > * {
        color: var(--gray-1);
}

.placeholder\:text-modal_button::placeholder {
        font-size: 3.733vw;
        line-height: 5.067vw;
        font-weight: 500;
}

.placeholder\:text-modal_content::placeholder {
        font-size: 4.267vw;
        line-height: 6.400vw;
        font-weight: 400;
}

.placeholder\:tracking-\[0\.213vw\]::placeholder {
        letter-spacing: 0.213vw;
}

.placeholder\:text-gray_2::placeholder {
        color: var(--gray-2);
}

.after\:absolute::after {
        content: var(--tw-content);
        position: absolute;
}

.after\:-bottom-\[1vw\]::after {
        content: var(--tw-content);
        bottom: -1vw;
}

.after\:bottom-0::after {
        content: var(--tw-content);
        bottom: 0px;
}

.after\:left-0::after {
        content: var(--tw-content);
        left: 0px;
}

.after\:right-0::after {
        content: var(--tw-content);
        right: 0px;
}

.after\:z-10::after {
        content: var(--tw-content);
        z-index: 10;
}

.after\:block::after {
        content: var(--tw-content);
        display: block;
}

.after\:h-\[1\.86vw\]::after {
        content: var(--tw-content);
        height: 1.86vw;
}

.after\:w-\[3\.2vw\]::after {
        content: var(--tw-content);
        width: 3.2vw;
}

.after\:w-full::after {
        content: var(--tw-content);
        width: 100%;
}

.after\:bg-badge_blue::after {
        content: var(--tw-content);
        background-color: #447EFF1F;
}

.after\:text-blue::after {
        content: var(--tw-content);
        --tw-text-opacity: 1;
        color: rgb(68 126 255 / var(--tw-text-opacity, 1));
}

.after\:content-\[\"\"\]::after {
        --tw-content: "";
        content: var(--tw-content);
}

.first\:pl-36:first-child {
        padding-left: 4.800vw;
}

.first\:after\:content-\[\'\'\]:first-child::after {
        --tw-content: '';
        content: var(--tw-content);
}

.last\:mb-48:last-child {
        margin-bottom: 6.400vw;
}

.last\:border-l-0:last-child {
        border-left-width: 0px;
}

.last\:pb-0:last-child {
        padding-bottom: 0px;
}

.last\:pr-36:last-child {
        padding-right: 4.800vw;
}

.dark\:block:is(.dark *) {
        display: block;
}

.dark\:hidden:is(.dark *) {
        display: none;
}

.dark\:bg-\[\#181923\]:is(.dark *) {
        --tw-bg-opacity: 1;
        background-color: rgb(24 25 35 / var(--tw-bg-opacity, 1));
}

.dark\:bg-\[\#1E140D\]:is(.dark *) {
        --tw-bg-opacity: 1;
        background-color: rgb(30 20 13 / var(--tw-bg-opacity, 1));
}

.dark\:bg-\[\#FFF\]\/10:is(.dark *) {
        background-color: rgb(255 255 255 / 0.1);
}

.dark\:text-\[\#DBB89A\]:is(.dark *) {
        --tw-text-opacity: 1;
        color: rgb(219 184 154 / var(--tw-text-opacity, 1));
}

.dark\:shadow-\[0_-0\.8vw_1\.333vw_0_rgba\(0\2c 0\2c 0\2c 0\.1\)\]:is(.dark *) {
        --tw-shadow: 0 -0.8vw 1.333vw 0 rgba(0,0,0,0.1);
        --tw-shadow-colored: 0 -0.8vw 1.333vw 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
