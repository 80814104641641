#alertV2{
    width: 100vw;
    position: fixed;
    top: calc(50vh - 21vw);
    left: 0;
    z-index: 900000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.global-alertV2{
    height: 12vw;
    background: rgba(0, 0, 0, .75);
    border-radius: 1.6vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.global-alertV2-textCont{
    padding: 0 4vw;
    font-size: 4vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.global-alertV2-textCont img{
    width: 5.33vw;
    height: 5.33vw;
    margin-right: 1vw;
}