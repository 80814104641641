.Zoom-enter {
  opacity: 0;
  -webkit-transform: scale(0.6);
          transform: scale(0.6)
}
.Zoom-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 600ms;
  transition: all 600ms;
}
.Zoom-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.Zoom-exit-active {
    opacity: 0;
    -webkit-transform: scale(.6);
            transform: scale(.6);
    -webkit-transition: all 600ms;
    transition: all 600ms
}
.ZoomQuick-enter {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6)
  }
.ZoomQuick-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 300ms;
    transition: all 300ms;
}
.ZoomQuick-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.ZoomQuick-exit-active {
    opacity: 0;
    -webkit-transform: scale(.6);
            transform: scale(.6);
    -webkit-transition: all 300ms;
    transition: all 300ms
}

.Left_to_Right-enter {
    opacity: 0;
    -webkit-transform:translateX(-100%);
            transform:translateX(-100%)
}
.Left_to_Right-enter-active {
    opacity: 1;
    -webkit-transform:translateX(0%);
            transform:translateX(0%);
    -webkit-transition: all 600ms;
    transition: all 600ms
  }
.Left_to_Right-exit {
    opacity: 1;
    -webkit-transform:translateX(0%);
            transform:translateX(0%)
}
.Left_to_Right-exit-active {
    opacity: 0;
    -webkit-transform:translateX(-100%);
            transform:translateX(-100%);
    -webkit-transition: all 600ms;
    transition: all 600ms
}

.Right_to_Left-enter {
    opacity: 0;
    -webkit-transform:translateX(100%);
            transform:translateX(100%)
}
.Right_to_Left-enter-active {
    opacity: 1;
    -webkit-transform:translateX(0%);
            transform:translateX(0%);
    -webkit-transition: all 600ms;
    transition: all 600ms
  }
.Right_to_Left-exit {
    opacity: 1;
    -webkit-transform:translateX(0%);
            transform:translateX(0%)
}
.Right_to_Left-exit-active {
    opacity: 0;
    -webkit-transform:translateX(100%);
            transform:translateX(100%);
    -webkit-transition: all 600ms;
    transition: all 600ms
}

.Botoom_to_Top-enter {
    opacity: 0;
    -webkit-transform:translateY(100%);
            transform:translateY(100%)
}
.Botoom_to_Top-enter-active {
    opacity: 1;
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    -webkit-transition: all 600ms;
    transition: all 600ms
  }
.Botoom_to_Top-exit {
    opacity: 1;
    -webkit-transform:translateY(0%);
            transform:translateY(0%)
}
.Botoom_to_Top-exit-active {
    opacity: 0;
    -webkit-transform:translateY(100%);
            transform:translateY(100%);
    -webkit-transition: all 600ms;
    transition: all 600ms
}

.Top_to_Bottom-enter {
    opacity: 0;
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%)
}
.Top_to_Bottom-enter-active {
    opacity: 1;
    -webkit-transform:translateY(0%);
            transform:translateY(0%);
    -webkit-transition: all 600ms;
    transition: all 600ms
  }
.Top_to_Bottom-exit {
    opacity: 1;
    -webkit-transform:translateY(0%);
            transform:translateY(0%)
}
.Top_to_Bottom-exit-active {
    opacity: 0;
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%);
    -webkit-transition: all 600ms;
    transition: all 600ms
}

.Fade_in_out-enter {
    opacity: 0;
}
.Fade_in_out-enter-active {
    opacity: 1;
    -webkit-transition: all 600ms;
    transition: all 600ms
  }
.Fade_in_out-exit {
    opacity: 1;
}
.Fade_in_out-exit-active {
    opacity: 0;
    -webkit-transition: all 600ms;
    transition: all 600ms
}