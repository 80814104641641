#alertNotice{
    position: fixed;
    top: calc(50vh - 21vw);
    left: 15vw;
    -webkit-transform: translateY(-15vw);
            transform: translateY(-15vw);
    z-index: 900000000;
}
.global-alertNotice{
    width: 70vw;
    background: #FFFFFF;
    border-radius: 4vw;
}
.global-alertNotice-textCont{
    height: 30vw;
    padding: 0 5vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 4.53vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
}
.global-alertNotice-btn{
    height: 12vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-top: 1px solid #EEEEEE;
    font-size: 4.53vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF9626;
}