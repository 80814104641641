.tabbar-container {
    -webkit-user-select: none;
       -moz-user-select: none;
                -ms-user-select: none;
            user-select: none;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 17vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background: #fff;
    -webkit-box-shadow: 0px -16px 16px 0px rgba(0, 0, 0, .03);
            box-shadow: 0px -16px 16px 0px rgba(0, 0, 0, .03);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}

.tab {
    height: 100%;
    font-size: 3vw;
    font-family: PingFangSC-Medium, Noto Sans SC;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 2.8vw 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.tab-icon {
    height: 6.4vw;
    width: 6.4vw;
}
/*.tabbar-container div:nth-child(3) img {*/
/*    width: 11.7vw;*/
/*    height: 7.2vw;*/
/*}*/
