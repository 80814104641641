html,
body {
    margin: 0 0;
    padding: 0 0;
    -webkit-user-select: none;
       -moz-user-select: none;
                -ms-user-select: none;
            user-select: none;
    /* font-family: PingFangSC, Helvetica, sans-serif; */
    -webkit-text-size-adjust : none ;
    -moz-text-size-adjust : none ;
    -ms-text-size-adjust : none;
        text-size-adjust : none;
    -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
    display: none;
}
body{
    overflow: hidden;
}
/*@font-face {*/
/*    font-family: 'DIN';*/
/*    src: url("https://cheesestock.oss-cn-qingdao.aliyuncs.com/font/Cheese-Medium.ttf");*/
/*}*/

/*@font-face {*/
/*    font-family: 'DIN-Bold';*/
/*    src: url("https://cheesestock.oss-cn-qingdao.aliyuncs.com/font/Cheese-Bold.ttf");*/
/*}*/


/*@font-face {*/
/*    font-family: 'PingFangSC-Medium, PingFang SC';*/
/*    src: url("file:///android_asset/fonts/dfgb_k.ttf");*/
/*}*/
/*@font-face {*/
/*    font-family: 'PingFangSC-Medium, PingFang SC';*/
/*    src: url("dfgb_k.ttf");*/
/*}*/
/*@font-face {*/
/*    font-family: 'FZLTHK';*/
/*    src: url('dfgb_k.ttf') format('truetype')*/
/*}*/
@font-face {
    font-family: 'DIN';
    src: url("https://cheesestock.oss-cn-qingdao.aliyuncs.com/font/DINPro-Medium.otf");
}

@font-face {
    font-family: 'DIN-Bold';
    src: url("https://cheesestock.oss-cn-qingdao.aliyuncs.com/font/DINPro-Bold.otf");
}

@font-face {
    font-family: HelloFont-WenYiHei;
    src: url("https://cheesestock.oss-cn-qingdao.aliyuncs.com/font/WenYiHei.woff")
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);-webkit-tap-highlight-color: transparent; /* For some Androids */
}
@-webkit-keyframes opcity_animation {
    0%{
        opacity: 0.6;
    }
    100%{
        opacity: 1;
    }
}
@keyframes opcity_animation {
    0%{
        opacity: 0.6;
    }
    100%{
        opacity: 1;
    }
}
@-webkit-keyframes bottom_animation {
    from {
        bottom: -40vh;
    }
    to {
        bottom: 0;
    }
}
@keyframes bottom_animation {
    from {
        bottom: -40vh;
    }
    to {
        bottom: 0;
    }
}
.topSearch-RightBtn{
    height: 14.9vw;
    width: 14.9vw;
    margin-left: 4.38vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.flexSpaceBetween{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.marginTop2rem{
    margin-top: -2vw;
}
.marginTop2rem>div>div{
    margin-top: 4.26vw;
}
